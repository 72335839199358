import React from 'react';
import Navbar from '../component/Navbar';
import ImageSlideshow from '../component/ImageSlideshow';
import Fb from '../component/Fb';
import Announcement from '../component/Announcement';
import OnlineAgent from '../component/OnlineAgent';
import LocalAgent from '../component/LocalAgent';
import Footer from '../component/Footer';

const Home = () => {
    return (
        <>
            <div>
                <Navbar ></Navbar>
            </div>
            <ImageSlideshow></ImageSlideshow>
            <Fb></Fb>
            <Announcement></Announcement>
            <OnlineAgent></OnlineAgent>
            <LocalAgent></LocalAgent>
            <Footer></Footer>

        </>
    );
};

export default Home;