import React from 'react';
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';

const OnlineAgent = () => {
    return (
        <div>
            <div className='bg-white drop-shadow-2xl py-6 w-[90%] mx-auto rounded-lg'>
                <h1 className='mx-auto'>
                    অনলাইন এজেন্ট হবার নিয়ম
                </h1>

                <div className='px-2 mt-4'>
                    <span className='font-bold'>
                        অনলাইন এজেন্ট হওয়ার আগে লোকাল এজেন্ট এর নিয়ম গুলো এক নজরে পড়ে নিন
                    </span>
                    <br /> <br />

                    <a className='text-blue-700 italic mt-6' href="">
                        <span className='text-xl text-red-800'>
                            <i class="uil uil-paperclip"></i>
                        </span>
                        <span className='ml-2'>
                            <Link to="/local-agent">লোকাল এজেন্ট হবার নিয়ম জানতে এই লিঙ্ক এ ক্লিক করুন –</Link>
                        </span>
                    </a>
                </div>

                <ul className='flex flex-col gap-4 px-2 mt-3 text-sm'>
                    <li>
                        <span className='text-xl text-red-800'>
                            <i class="uil uil-volleyball"></i>
                        </span>
                        <span className='ml-2'>
                            অনলাইন এজেন্ট এর পয়েন্ট রেট সর্বনিম্ন ফিক্সড ৫০ টাকা করে।
                        </span>
                    </li>

                    <li>
                        <span className='text-xl text-red-800'>
                            <i class="uil uil-volleyball"></i>
                        </span>
                        <span className='ml-2'>
                            তবে মাস্টার এজেন্ট চাইলে তার রেট বাড়িয়ে রাখতে পারবে যদি তার কাছে পর্যাপ্ত পরিমাণ উইথড্র ব্যাকআপ না থাকে।
                        </span>
                    </li>

                    <li>
                        <span className='text-xl text-red-800'>
                            <i class="uil uil-shield-slash"></i>
                        </span>
                        <span className='font-bold ml-2'>
                            বিঃদ্রঃ মাস্টার এজেন্ট উইথড্র এর সময় সর্বোচ্চ ৩০ মিনিট সময় নিতে পারবে। ৩০ মিনিট এর মধ্যে উইথড্র সম্পন্ন না করতে পারলে তাকে এজেন্টশীপ থেকে বাদ দেওয়া বাধ্যতামূলক। 
                        </span>
                    </li>

                    <li>
                        <span className='text-xl text-red-800'>
                            <i class="uil uil-ban"></i>
                        </span>
                        <span className='ml-2'>
                            অনলাইন এজেন্ট কোন ভাবেই নিজে বেট ধরতে পারবেন না। যদি কোন ভাবে নিজে খেলেন, তাহলে অনতি বিলম্বে তাকে বাদ দেয়া হবে।
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default OnlineAgent;