import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./page/Home";
import Account from "./page/Account";
import Rules from "./page/Rules";
import LocalAgent from "./component/LocalAgent";
import Local from "./page/Local";
import Fblink from "./page/Fblink";
import Agentrule from "./page/Agentrule";
import OnlineAgent from "./component/OnlineAgent";
import OnlineAgnetRule from "./page/OnlineAgnetRule";
import Complain from "./page/Complain";
import Login from "./SiteAdmin/login";
import AdminPanel from "./SiteAdmin/admin";


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home></Home>,
    },

    {
      path: "/account",
      element: <Account></Account>,
    },

    {
      path: "/rules",
      element: <Rules></Rules>,
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/admin',
      element: <AdminPanel />
    },
    {
      path: "/local-agent",
      element: <Local></Local>,
    },
    {
      path: "/fb-group",
      element: <Fblink></Fblink>,
    },
    {
      path: "/agent-rules",
      element: <Agentrule></Agentrule>,
    },
    {
      path: "/online-agent-rules",
      element: <OnlineAgnetRule></OnlineAgnetRule>,
    },

    {
      path: "/complain",
      element: <Complain></Complain>,
    },

    

  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
