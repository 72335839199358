import React from 'react';
import Navbar from '../component/Navbar';

const Fblink = () => {
    return (
        <div>
            <Navbar></Navbar>

            <div className='mt-14'>
                <div className='bg-white drop-shadow-2xl py-6 w-[90%] mx-auto rounded-lg '>
                    <h1>
                        Bazzi365 এর ফেইসবুক লিংক
                    </h1>

                    <div className='text-center '>
                        <p className='text-[12px]'>
                            Bazzi365 এর  অফিসিয়াল ফেসবুক গ্রুপ আছে যেটা তে আপনি জয়েন দিয়ে রাখতে পারেনঃ
                        </p>
                        <p className='text-[14px] break-words'>
                            গ্রুপ লিংক  : <span className='text-blue-700 font-semibold  max-w-xs'><a  href="https://www.facebook.com/groups/bazzi365.com.official">https://www.facebook.com/groups/bazzi365.com.official</a></span>
                        </p>

                        <p className='text-[12px]'>
                            Bazzi365 এর  অফিসিয়াল ফেসবুক পেইজ আছে যেটা তে আপনি লাইক দিয়ে রাখতে পারেনঃ
                        </p>
                        <p className='text-[12px]'>
                            পেইজ লিংক : <span className='text-blue-700 font-semibold'><a href=""></a></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fblink;