import React from "react";
import ProtectedRoute from "../util/protectedRoute";
import { useNavigate } from "react-router-dom";

const AdminPanel = ()=> {

    const navigate = useNavigate();

    const handleLogout = ()=> {
        localStorage.removeItem('token');
        navigate('/login')
    }

    return (
        <ProtectedRoute>
            <div>
                <h1>Hello Admin</h1>
                <button className="cocktail" onClick={handleLogout}>Logout</button>
            </div>
        </ProtectedRoute>
    );
}

export default AdminPanel