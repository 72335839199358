import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import "./login.css"
import { useNavigate } from 'react-router-dom';
import Navbar from '../component/Navbar';

const LOGIN_MUTATION = gql`
mutation Login($input: UsersPermissionsLoginInput!){
    login(input: $input){
      jwt
    }
  }`;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [login, { loading, error, data }] = useMutation(LOGIN_MUTATION);

  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const result = await login({
        variables: {
          input: {
            provider: '', // Modify this as needed
            identifier: username,
            password: password,
          },
        },
      });

      const jwtToken = result.data.login.jwt;

      // Save the JWT token to local storage
      localStorage.setItem('token', jwtToken);

      navigate('/admin')
      // Optionally, you can redirect the user to another page after successful login
      
      
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className="pt-24 container max-w-sm">
        <Navbar />
      <h1 className="text-center">Login</h1>
      <form onSubmit={handleLogin}>
        <label htmlFor="username">Username</label>
        <br />
        <input
          className="border border-black rounded"
          type="text"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <label htmlFor="password">Password</label>
        <br />
        <input
          className="border border-black rounded"
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <button className='cocktail' type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      {error && <p>Error: {error.message}</p>}
    </div>
  );
};

export default Login;