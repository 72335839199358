import React from 'react';

const LocalAgent = () => {
    return (
        <div className='mt-4'>
            <div className='bg-white drop-shadow-2xl py-6 w-[90%] mx-auto rounded-lg'>
                <h1>
                    লোকাল এজেন্ট হবার নিয়ম
                </h1>

                <p>
                    এজেন্ট হবার জন্য আমাদের সুপার এজেন্ট দের সাথে যোগাযোগ করতে হবে।
                </p>
                <p>
                    <a className='text-blue-700 italic' href="https://bazzi365agentlist.com/superadmin" target='blank'>
                        <span className='text-xl text-red-700'>
                            <i class="uil uil-paperclip"></i>
                        </span>

                        <span className='ml-2'>
                            সুপার এজেন্ট লিস্ট দেখতে এই লিঙ্ক এ ক্লিক করুন।
                        </span>
                    </a>
                </p>
                <h3>
                    পয়েন্ট কেনা বেচাঃ
                </h3>
                <p>
                    ** এজেন্ট Bazzi365 থেকে পয়েন্ট কিনবে ৫০ টাকা করে।<br />
                    ** এজেন্ট ইউজার এর কাছে বিক্রি করবে ১০০ টাকা করে।<br />
                    ** এই খানে এজেন্ট এর লাভ থাকবে ৫০% । <br />
                </p>
                <h3>
                    আবারঃ
                </h3>
                <p>
                    ** এজেন্ট ইউজার এর কাছ থেকে পয়েন্ট কিনবে ১০০ টাকা করে।<br />
                    ** এজেন্ট Bazzi365 এর কাছে বিক্রি করবে ৫০ টাকা করে।<br />
                    ** এই খানে এজেন্ট এর লস হবে ৫০%  – কিন্তু এই খানে কথা আছে<br />
                    ** এজেন্ট যত পয়েন্ট কিনবে Bazzi365 থেকে তার চেয়ে যদি বিক্রি বেশি হয় – এই ৫০% লস এর দায় ভার Bazzi365 &#40;কোম্পানি&#41; নিবে।<br />
                </p>
                <h3>

                    যেমনঃ
                </h3>
                <p>
                    এজেন্ট Bazzi365 থেকে ৩ মাসে পয়েন্ট কিনেছে – ৫,০০০ পয়েন্ট।
                    এই খানে তার লাভ হয়েছে – ৫,০০০x৫০= ২,৫০,০০০ টাকা। কিন্তু ৪ নং মাসে গিয়ে দেখা গেল এজেন্ট এর ইউজার রা অনেক জিতেছে এবং এজেন্ট কে বিক্রি করতে হচ্ছে Bazzi365 অথবা কোম্পানির কাছে ৭,০০০ পয়েন্ট । তাহলে এজেন্ট এর লাভ থেকে লস হবে ২,৫০,০০০ টাকা। কারন এজেন্ট কে Bazzi365 এর কাছে ৫,০০০ পয়েন্ট বিক্রি করতে হবে ৫০ টাকা রেট এই। বাকি ২০০০ পয়েন্ট বিক্রি করতে যে লস হবে ২,০০০x৫০=১,০০,০০টাকা, এই টাকা Bazzi365 &#40;কোম্পানি&#41; ভর্তুকি দিবে। ভর্তুকি দিবে এই কারণে, যাতে এজেন্ট এর নিজের পকেট থেকে কোন লস না হয়।
                </p>
                <br />
                <p>
                    তাহলে প্রশ্ন আসতে পারে, এজেন্ট হবার লাভ কোথায়? এজেন্ট এর মুল লাভ হচ্ছে ২% কমিশন এক্সচেঞ্জ এ। যেমন ১ জন ইউজার দিনে ১০ টা বেট করল । ৫ টা তে জিতল এবং ৫ টা তে হারল। দিন শেষে যে ৫ টা জিতেছে ইউজার – প্রতিবার এজেন্ট কে ২% কমিশন দিয়েছে ইউজার।
                    একজন এজেন্ট এর যদি ২০ থেকে ৫০ জন একটিভ প্লেয়ার থাকে – তার মাসে ২০ থেকে ৫০ হাজার টাকা কমিশন থাকে।</p>
                <br />
                <p>
                    মোট কথা এজেন্ট না হলে এজেন্ট হবার লাভ কি তা আপনি বুঝতে পারবেন না।
                </p>

                <h3>
                    শর্ত সমুহঃ
                </h3>
                <p>
                    ** এজেন্ট কে মিনিমাম ৩০০ পয়েন্ট কিনে এজেন্ট শুরু করতে হবে।<br />
                    ** ইউজার ব্যালেন্স বাদ দিয়ে – এজেন্ট এর কাছে সব সময় ৫০০ পয়েন্ট এর ব্যালেন্স থাকতে হবে।<br />
                    ** এজেন্ট ব্যালেন্স যদি ১০০০ পয়েন্ট হয়ে যায় তাহলে এজেন্ট ৫০০ পয়েন্ট Bazzi365 ( কোম্পানি ) এর কাছে বিক্রি করতে পারবেন।<br />
                    ** এজেন্ট – Bazzi365 ( কোম্পানি ) থেকে ৫০০ পয়েন্ট এর নিচে ক্রয় বিক্রয় করতে পারবেন না।<br />
                    ** এজেন্ট এর কাছে সর্বনিম্ন ৩০ জন একটিভ প্লেয়ার থাকতে হবে।<br />
                    *** এজেন্ট ইউজার সাথে প্রতি পয়েন্ট কেনা বেচা করবে ১০০ টাকা করে।
                </p>
                
                
                <h3>
                    কি কি কারনে এজেন্ট বাতিল হয়ে যেতে পারেঃ
                </h3>
                <p>
                    ** একটা বিষয় অবগত করা দরকার। এজেন্ট এর যদি নিজে খেলার অভ্যাস থাকে তাহলে – এজেন্ট হওয়া থেকে বিরত থাকুন। এজেন্ট যদি কখনো নিজে খেলতে গিয়ে ধরা পরেন – তাহলে এজেন্ট একাউন্ট ঐ অবস্থায় সঙ্গে সঙ্গে ব্লক হয়ে যাবে এবং সমস্ত লেনদেন বন্ধ হয়ে যাবে। যদি এজেন্ট নিজে কোন আইডি তে বাজি ধরিয়ে দিতে চান – তাহলে Bazzi365 (কোম্পানি) অথবা আপনার আপলাইন কে আগেই সেই আইডি'র বিষয়ে জানাতে হবে।<br />
                    ** কোন ইউজার এর পয়েন্ট ইউজার কে না জানিয়ে তুলে নিলে।<br />
                    ** ইউজার একাউন্ট থেকে পয়েন্ট তোলার ৩০ মিনিট মধ্যে টাকা টা ইউজার কে বুঝিয়ে না দিলে।<br />
                    ** ইউজার পাসোয়ার্ড ইউজার কে না জানিয়ে পরিবর্তন করলে।<br />
                    ** ইউজার এর একাউন্ট খোলার সময় কোম্পানীর নিয়ম নিতি না মানলে।<br />
                    ** ইউজার এর একাউন্ট খোলার সময় – ইউজার এর ফোন নাম্বার এর বদলে অন্য কারো ফোন নাম্বার ব্যবহার করলে।<br />
                    ** ডিপোজিট বা উইথড্র এর ক্ষেত্রে কোম্পানীর নিয়ম না মানলে।
                </p>
                <p>
                    সিস্টেম জানা শেষ হলে আপনাকে নিচের লিংক এ ক্লিক করে সুপার এজেন্ট লিষ্ট দেখতে হবে।
                </p>
                <p>
                    <a className='text-blue-700' href="https://bazzi365agentlist.com">
                        https://bazzi365agentlist.com
                    </a>
                </p>

                <p>
                    এই লিষ্ট এর সব সুপার এর সাথে এজেন্ট হবার বিষয় টি নিয়ে আলাপ করেন – যে সুপার সাথে কথা বলে আপনার ভাল লাগবে তার কাছ থেকে এজেন্ট নিতে পারবেন।
                </p>
            </div>
        </div>
    );
};

export default LocalAgent;