import React, { useState } from 'react';
import Logo from '../asset/bzilogo.png';
import { motion, AnimatePresence } from 'framer-motion';
import {Link} from 'react-router-dom';

const Navbar = () => {

    const [clicked, setClicked] = useState(false);

    return (
        <div className='fixed left-0 right-0 top-0 z-50'>
            <div className='relative'>
                <nav className="bg-[#C80101] bg-opacity-90 backdrop-blur-xl p-4 max-w-full z-50">
                    <div className="mx-auto flex justify-between items-center">

                        {/* ========== nav logo ========== */}

                        <div className="">
                            <Link to="/">
                                <img className='w-[100px] shadow-md shadow-[#2a2a2a] ' src={Logo} alt="" />
                            </Link>
                        </div>

                        {/* ========== nav mid ========= */}
                        <div className="">
                            <a className="font-gabarito font-extrabold text-[18px] text-white drop-shadow-lg" href="/">Bazzi365.info</a>
                        </div>

                        {/* ========== nav menu btn ====== */}

                        <div className="text-[#C80101]">
                            <button
                                onClick={() => setClicked(!clicked)}

                                className={clicked ? 'rounded-full py-2 px-4 flex gap-1 text-[18px] bg-black text-white duration-300' : 'bg-white rounded-full py-2 px-4 flex gap-1 text-[18px] duration-300'}>
                                <span>
                                    Menu
                                </span>
                                <span className='duration-300'>
                                    {
                                        clicked ? <i class="uil uil-times"></i> : <i class="uil uil-bars"></i>
                                    }
                                </span>
                            </button>
                        </div>
                    </div>


                </nav>

                {/* ======== drop down ====== */}

                <AnimatePresence>
                    {


                        <motion.div

                            initial={{

                                x: '100vw',
                            }}
                            animate={clicked ? { x: 0 } : {
                                x: '100vw',
                            }}
                            transition={{
                                type: "spring",
                                damping: 8,
                                stiffness: 120,
                                restDelta: 0.001,
                            }}

                            className='fixed bg-violet-950 w-full backdrop-blur-lg h-screen py-16'>

                            <div className='flex flex-col gap-7 font-extrabold text-[14px text-white px-6  z-50 overflow-scroll nav-scroll h-[85%] '>

                                {/* ====== 1 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'>
                                        <i class="uil uil-arrow-circle-right"></i>
                                    </div>
                                    <div>
                                        <Link to='/account'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>
                                                Bazzi 365 &nbsp;
                                            </span>
                                            তে কিভাবে একাউন্ট খুলবেন?
                                        </Link>
                                    </div>
                                </a>

                                {/* ====== 2 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='/rules'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>
                                                Bazzi 365&nbsp;
                                            </span>
                                            তে কিভাবে লেনদেন করবেন?
                                            </Link>
                                    </div>
                                </a>

                                {/* ====== 3 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='/fb-group'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>
                                                Bazzi 365&nbsp;
                                            </span> এর ফেইসবুক গ্রুপ লিঙ্ক কোন টা?
                                            </Link>
                                    </div>
                                </a>

                                {/* ====== 4 ====== */}
                                <a className='flex' href="">

                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='/rules'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>
                                                Bazzi 365&nbsp;
                                            </span>
                                            তে একাউন্ট খোলার নিয়ম বা শর্ত গুলো কি কি?
                                            </Link>
                                    </div>
                                </a>

                                {/* ====== 5 ====== */}
                                <a className='flex' href="">

                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='https://bazzi365agentlist.com/services'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>Bazzi 365&nbsp;</span> এর কাস্টমার সার্ভিস এর হোয়াটসঅ্যাপ নাম্বার গুলো কি কি?
                                            </Link>
                                    </div>
                                </a>

                                {/* ====== 6 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='https://bazzi365agentlist.com/master'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>Bazzi 365&nbsp;</span> এর মাস্টার এজেন্ট দের হোয়াটসঅ্যাপ নাম্বার গুলো কি কি?
                                            </Link>
                                    </div>
                                </a>

                                {/* ====== 7 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='https://bazzi365agentlist.com/superadmin'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>Bazzi 365&nbsp;</span> এর সুপার এজেন্ট দের হোয়াটসঅ্যাপ নাম্বার গুলো কি কি?
                                        </Link>
                                    </div>
                                </a>

                                {/* ====== 8 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='/agent-rules'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>Bazzi 365&nbsp;
                                            </span>
                                            তে কিভাবে আমি এজেন্ট হতে পারি?
                                        </Link>
                                    </div>
                                </a>

                                {/* ====== 9 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='/online-agent-rules'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>Bazzi 365&nbsp;
                                            </span>
                                            তে কিভাবে আমি অনলাইন মাষ্টার এজেন্ট হতে পারি?
                                        </Link>
                                    </div>
                                </a>

                                {/* ====== 10 ====== */}
                                <a className='flex' href="">
                                    <div className='px-2 text-xl'><i class="uil uil-arrow-circle-right"></i></div>
                                    <div>
                                        <Link to='/complain'>
                                            <span className='font-bold text-xl text-red-400 drop-shadow-2xl'>Bazzi 365&nbsp;</span>তে কিভাবে এজেন্ট এর বিরুদ্ধে অভিযোগ করতে পারি?
                                        </Link>
                                    </div>
                                </a>
                                <div className='pacifico text-center mt-2'>
                                    <span><i class="uil uil-record-audio"></i></span>Thank You
                                </div>
                            </div>

                        </motion.div>


                    }
                </AnimatePresence>


            </div>
        </div>
    );
};

export default Navbar;