import React from 'react';
import Navbar from '../component/Navbar';
import { Link } from 'react-router-dom';

const Account = () => {
    return (
        <div>
           <Navbar></Navbar>

           <div className='mt-14'>
                <div className='bg-white drop-shadow-2xl py-6 w-[90%] mx-auto rounded-lg'>
                    <h1 className='mx-auto'>
                        <span className='font-bold text-xl drop-shadow-2xl'>
                            Bazzi365 &nbsp;
                        </span>
                        তে কিভাবে একাউন্ট খুলবেন?
                    </h1>

                    <div className='px-2 mt-4'>
                        <span className='font-bold'>
                            সবার আগে পড়ে নিন – পুরোটা না পড়ে একাউন্ট খুলবেন না।
                        </span>
                        <br /> <br />

                        
                    </div>

                    <ul className='flex flex-col gap-4 px-2 mt-3 text-sm'>
                        <li>
                            
                            <span className='ml-2'>
                                Bazzi365 একটি – এজেন্ট বেইজ বেটিং ওয়েব সাইট। Bazzi365 তে একাউন্ট খুলতে হলে এবং ডিপোজিট করতে হলে আপনাকে এজেন্ট এর সাহায্য নিতে হবে। এজেন্ট ছাড়া আপনি একাউন্ট খুলতে বা ডিপোজিট বা উইথড্র করতে পারবেন না। আপনি যে এজেন্ট এর মাধ্যমে একাউন্ট খুলবেন – তার মাধ্যমেই আপনাকে ডিপোজিট বা উইথড্র করতে হবে। তাই সঠিক এজেন্ট নির্বাচন করা জরুরী।

                            </span>
                        </li>

                        <li>
                            
                            <span className='ml-2'>
                                আমাদের এজেন্ট লিষ্ট দেখতে নিচের লিঙ্ক এ ক্লিক করুনঃ
                            </span>
                            
                            <span className='ml-2'><a className='text-blue-700' href="https://bazzi365agentlist.com">https://bazzi365agentlist.com</a></span>
                        </li>

                        

                        <li>
                            
                            <span className='ml-2'>
                                এই এজেন্ট লিষ্ট এর বাইরে কারো সাথে লেনদেন করলে আপনাকে নিজ দ্বায়িত্বে লেনদেন করতে হবে। লিষ্ট এর বাইরে কোন এজেন্ট এর দায়ভার Bazzi365 (কোম্পানি) নিবে না এবং লিষ্ট এর এজেন্ট দের সব দায়ভার  Bazzi365 বা কোম্পানীর। এজেন্ট লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসঅ্যাপ এর মাধ্যমে যোগাযোগ করতে হবে। হোয়াটসঅ্যাপ ছাড়া অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। হোয়াটসঅ্যাপ এ যোগাযোগ করতে হলে এজেন্ট লিস্টে হোয়াটসঅ্যাপ আইকন উপরে ক্লিক করুন অথবা ফোন নাম্বার টি মোবাইলে সেভ করে তাকে হোয়াটসঅ্যাপ এ মসেজ পাঠাতে পারবেন। হোয়াটসঅ্যাপ এপ টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল প্লে-স্টর থেকে ইন্সটল করে নিন।
                            </span>
                        </li>

                        <li>
                            
                            <span>
                                অন্যান্য সাহায্যের জন্যঃ<br />
                                তথ্যাদি এবং সাহায্যের জন্য হোয়াটসাপ নাম্বারঃ<br />
                                <a className='text-blue-700' href="https://wa.me/17867233673">+1 (786) 723-3673  ( এডমিন )</a>
                            </span>
                        </li>

                        <li>

                            আমাদের ফেসবুক গ্রুপের লিঙ্কঃ<br />
                            <a className='text-blue-700' href="https://m.facebook.com/groups/bazzi365.com.official">https:// m.facebook.com/groups/ bazzi365.com.official</a>
                        </li>

                        <li>

                            এছাড়া আপনারা আমাদের কাস্টমার সার্ভিস এর সাথে সরাসরি যোগাযোগ করতে পারেন। তাদের নাম্বার গুলো জানতে নিচের লিঙ্কে ক্লিক করুনঃ
                            <br />
                            <a className='text-blue-700' href="https://wa.me/17867233673">Customer Service</a>
                        </li>

                        <li>

                            একাউন্ট খোলার আগে নিচের লিঙ্কে ক্লিক করে শর্ত সমুহ জেনে নিন। এই শর্ত সমুহ জানা অত্যন্ত জরুরীঃ
                            
                            <br />
                            <Link to='/rules' className='text-blue-700 ita' href="https://wa.me/17867233673">নিয়মগুলো জানার জন্য এই লিঙ্ক এ ক্লিক করুন।</Link>
                        </li>
                    </ul>
                </div>
           </div>
        </div>
    );
};

export default Account;