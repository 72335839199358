import React from 'react';
import Marquee from "react-fast-marquee";
import {delay, motion} from 'framer-motion';
import facebook from '../asset/facebook.jpg.png';

const Fb = () => {
    return (
        <div className=' bg-gradient-to-br from-cyan-500 to-blue-500 text-white py-3 flex font-bold font-gabarito mt-2'>


            <div  className='pl-3 text-blue-600 rounded-lg '>
                <img className='w-[18px] mt-1'  src={facebook} alt="" />
            </div>

            <Marquee className="ml-3 px-3"
                autoFill={false}
                speed={40}
                
                
            >
                <span>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; আমাদের অফিসিয়াল ফেইসবুক গ্রুপ &nbsp;:
                
                </span>
                <span><a href="https://www.facebook.com/groups/bazzi365.com.official">
                    &nbsp; https://www.facebook.com/groups/bazzi365.com.official &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </a>
                </span>

            </Marquee>



        </div>


      

        
    );
};

export default Fb;


