import React from 'react';
import Marquee from "react-fast-marquee";
import bkash from '../asset/bKash-logo.png';
import nagad from '../asset/Nagad-Logo.png';
import rocket from '../asset/dutch-bangla-rocket-logo.png';
import dbbl from '../asset/Dutch_Bangla_Bank.png';

const Footer = () => {
    return (
        <div className='mt-6'>
            <div className='bg-[#C80101] w-full text-center font-bold text-white py-3 uppercase rounded-t-xl'>
                <div className='flex flex-col gap-2'>
                    <div className='text-sm'>
                        Agentlist Link <span className='text-xl'><i class="uil uil-link"></i></span> <span>
                            <a className='text-sky-400' href="https://bazzi365agentlist.com/" target='blank'>bazzi365agentlist.com</a></span>
                    </div>
                    <div className='text-sm'>
                        Site Link <span className='text-xl'><i class="uil uil-link"></i></span> <span>
                            <a className='text-sky-400' target='blank' href="https://www.bazzi365.com/#/dash">bazzi365.com</a></span>
                    </div>
                    <div className='text-3xl flex justify-center gap-5 py-3'>
                        <a className='' href="https://www.facebook.com/groups/bazzi365.com.official">
                            <i class="uil uil-facebook-f"></i>
                        </a>
                        <a href="https://wa.me/+17867233673">
                            <i class="uil uil-whatsapp"></i>
                        </a>
                        <a href="bazzi365official@gmail.com">
                            <i class="uil uil-envelope"></i>
                        </a>
                        <a href="https://join.skype.com/invite/sglomgiGgZrh">
                            <i class="uil uil-skype"></i>
                        </a>
                    </div>
                </div>
                <div className='text-xl font-bold tit-neon tracking-wide'>
                    Thank You
                </div>

                <div className='mt-3 text-xs font-light tracking-[3px]'>
                    All rights reserved by © BAZZI 365
                </div>


            </div>
            <div className='py-1'>
                <Marquee speed={30} autoFill={true}>
                    <img className='w-20 bg-white mx-4' src={bkash} alt="" />
                    <img className='w-20 bg-white mx-4' src={nagad} alt="" />
                    <img className='w-20 bg-white mx-4' src={rocket} alt="" />
                    <img className='w-20 bg-white mx-4' src={dbbl} alt="" />
                </Marquee>
            </div>
        </div>
    );
};

export default Footer;