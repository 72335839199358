import React from 'react';
import Marquee from "react-fast-marquee";

const Announcement = () => {
    return (

        <div className=' bg-white text-black py-3 flex font-bold font-gabarito mt-2 border-t border-b border-black'>


            <div className='pl-3 border-r border-black pr-2'>
                <i class="uil uil-microphone"></i>
            </div>

            <Marquee className="px-3 ml-3"
                speed={30}
                autoFill={false}

            >
                <span>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; আমাদের অফিসিয়াল এজেন্ট লিষ্ট  &nbsp;: &nbsp; <span><a href="https://bazzi365agentlist.com/">https://bazzi365agentlist.com &nbsp; &nbsp; &nbsp;</a></span>
                </span>
                <span>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Once player account found with fraudulent ticket, the respective market will be voided and the player account will be locked.&nbsp; &nbsp; &nbsp;
                </span>

                <span>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; WELCOME TO BAZZI 365 ! ENJOY BETTING IN MATCH ODDS, ﻿FANCY & LIVE CASINO &nbsp; &nbsp; &nbsp;
                </span>

            </Marquee>



        </div>

    );
};

export default Announcement;