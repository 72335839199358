import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import one from '../asset/1.jpeg';
import two from '../asset/2.jpeg';
import three from '../asset/3.jpeg';
import four from '../asset/4.jpeg';
import five from '../asset/5.jpeg';


const ImageSlideshow = () => {

  

    return (
        <div className='mt-[75px]'>
            <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            
            >
                <div >
                    <img src={one} />
                    
                </div>
                <div>
                    <img src={two} />
                    
                    
                </div>
                <div >
                    
                    <img src={three} />
                    
                </div>
                <div>
                    <img src={four} />
                    
                </div>
                <div>
                    <img src={five} />
                    
                </div>
            </Carousel>
        </div>
    );
};

export default ImageSlideshow;
