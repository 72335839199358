import React from 'react';
import Navbar from '../component/Navbar';


const Rules = () => {
    return (
        <div>
            <Navbar></Navbar>

            <div className='mt-14'>
                <div className='bg-white drop-shadow-2xl py-6 w-[90%] mx-auto rounded-lg'>
                    <h1 className='mx-auto'>
                        Bazzi365 (কোম্পানি)–র শর্ত এবং নিয়ম 
                    </h1>

                    <div className='px-2 mt-4'>
                        <span className='font-bold'>
                            Bazzi365 এর প্রধান শর্ত এবং নির্দেশনা সমুহ।
                        </span>
                        <br /> <br />


                    </div>

                    <ul className='flex flex-col gap-4 px-2 mt-3 text-sm'>
                        <li>
                            
                            <span className='ml-2'>
                                সকল সুপার এবং মাস্টার এজেন্ট এবং সকল ইউজার এই নিয়মের অন্তর্গত।

                            </span>
                        </li>

                        <li>
                            
                            <span className='font-bold'>ওয়েবসাইট টি যেহেতু বেটফেয়ার এর মাধ্যমে চলে – সেহেতু বেটফেয়ার এর নিয়ম এবং স্বিদ্ধান্ত অনুযায়ী সব কিছু হয়। এই বেট সাইটে বেট করতে হলে আপনাকে বেটফেয়ার</span> এবং Bazzi365 ম্যানেজমেন্ট এর যে কোন স্বিদ্ধান্ত আপনাকে বিনা শর্তে মেনে নিতে হবে।
                        </li>

                        <li>

                            <span className='font-bold'>ইউজার এর জন্য নির্দেশনা এবং শর্তঃ</span>
                        </li>



                        <li>
                            
                            ** প্রতিবার এজেন্ট এর কাছ থেকে পয়েন্ট নেবার আগে – এজেন্ট এর কাছে লেনদেন এর তথ্য জেনে নিতে হবে। যেহেতু এজেন্ট এক এক সময় এক ভাবে লেনদেন করে সেহেতু এই তথ্য জানা জরুরী।
                        </li>

                        <li>

                            <span className='font-bold'> * সকাল ৯ঃ৪৫ এর আগে এবং রাত ৯ঃ৪৫ এর পরে কোন ইউজার যদি এজেন্ট কে টাকা পাঠায় – অই টাকার দায়ভার Bazzi365 (কোম্পানি)</span> নিবে না।
                        </li>

                        <li>

                            ** বিকাশ/নগদ এ টাকা পাঠানোর ২ ঘন্টার মধ্যে এজেন্ট এর সাথে যোগাযোগ করে পয়েন্ট নিতে হবে। ২ ঘন্টা পরে – টাকা কাউন্ট করা হবে না।
                        </li>

                        <li>

                            ** প্রতিদিন বাংলাদেশ সময় ৯ঃ৩০ মিনিট এ – একাউন্ট এ কত পয়েন্ট আছে – তার একটা স্ক্রীনশট রাখতে হবে। যদি আপনি পয়েন্ট এর হিসাব না বুঝেন – তাহলে এই তথ্য আপনাকে সাহায্য করবে।
                        </li>

                        <li>

                            ** এজেন্ট ইউজার এর অনুমতি ব্যাতীত কোন পয়েন্ট ডিপোজিট বা উইথড্র করলে – সঙ্গে সঙ্গে এজেন্ট কে অভিযোগ করতে হবে – যদি এজেন্ট উত্তর না দেয় – তাহলে তার সুপার এজেন্ট কে অভিযোগ করতে হবে। যদি সুপার এজেন্ট উত্তর না দেয় – তাহলে সাব - এডমিন কে অভিযোগ করতে হবে।
                        </li>


                        <li>

                            ** এজেন্ট যদি ভুল করে কোন পয়েন্ট ডিপোজিট করে ফেলে – তাহলে ইউজার অই পয়েন্ট দিয়ে কোন ভাবেই বেট ধরতে পারবেন না। যদি পয়েন্ট দিয়ে বেট ধরে ফেলেন এতে পরবর্তীতে – এজেন্ট বা Bazzi365 ম্যানেজমেন্ট এর যে কোন স্বিদ্ধান্ত ইউজার কে মেনে তে নিতে হবে।
                        </li>


                        <li>

                            ** এজেন্ট এর সাথে আপনার হোয়াটসাপ বা মেসেঞ্জার এর তথ্যাদি এবং সব লেনদেন এর তথ্যাদি অন্তত ১৫ দিন সংরক্ষন করতে হবে।
                        </li>

                        <li>

                            ** Bazzi365 তে হোয়াটসাপ ছাড়া আর কোন এপ দিয়ে লেনদেন করা যাবে না।।
                        </li>

                        <li>

                            ** Bazzi365 এর একটি আইডি র জন্য একজন ইউজার একের অধিক হোয়াটসাপ ব্যবহার করতে পারবে না।
                        </li>

                        <li>

                            ** Bazzi365 তে একটি হোয়াটসাপ দিয়ে একের অধিক আইডি খোলা যাবে না।
                        </li>

                        <li>

                            ** খেলা শেষ হয়ে গেলে – বেট স্যাটেল হতে অথবা পয়েন্ট ফেরত দিতে ১৫ মিনিট থেকে ১ ঘন্টা সময় লাগে। তাই ১ ঘন্টার আগে কেন পয়েন্ট ফেরত আসলো না এই বিষয়ে অভিযোগ করা যাবে না।
                        </li>

                        <li>

                            ** ইউজার কে ক্যাসিনো খেলার আগে ক্যাসিনো খেলার সব নিয়ম জানার জন্য নির্দেশ দেয়া হচ্ছে।
                        </li>

                        <li>

                            ** সাইটে অন্য গেইম গুলো খেলার আগে – নিয়ম গুলো জেনে নিন। কোন কিছু না বুঝলে ফেইসবুক গ্রুপে পোষ্ট করতে হবে।
                        </li>

                        <li>

                            ** মাঝে মাঝে বেটফেয়ার স্কোর ভুল দেখায় – এই জন্য ইউজারদের কে, Bazzi365 এর পাশাপাশি অন্য সাইট থেকেও স্কোর দেখার জন্য নির্দেশ দেয়া হচ্ছে।
                        </li>

                        <li>

                            ** বেট করার পরে ইউজার দের বেট ম্যাচ হয়েছে কিনা তা দেখার জন্য অনুরোধ করা হলো। বেট ম্যাচ না হলে পরে সেই বেট ক্যান্সেল হয়ে যায়।
                        </li>

                        <li>

                            ** খেলা শেষ হয়ে গেলে – বেট স্যাটেল হতে অথবা পয়েন্ট ফেরত দিতে ১৫ মিনিট থেকে ১ ঘন্টা সময় লাগে। কিছু কিছু ক্ষেত্রে এই সময় ২৪ ঘন্টা পর্যন্ত যেতে পারে।
                        </li>

                        <li>

                            ** পয়েন্ট এর হিসাব এর কোন অভিযোগ যদি বেটফেয়ার কে করা হয়, তাদের দেয়া সমাধান ইউজার কে মেনে নিতে হবে। কারন এজেন্ট এর এই খানে কিছুই করার নেই।
                        </li>

                        <li className='font-bold'>বেটফেয়ার বা Bazzi365 সাইটের শর্তঃ</li>
                        <li>
                            ** বেটফেয়ার কোন কোন সময় বেট স্যাটেল করতে ভুল করতে পারে।
                        </li>
                        <li>
                            ** যদি বেটফেয়ার কোন বেট স্যাটেল করতে ভুল করে – তা পরে রিস্যাটেল করতে পারে বা করে।
                        </li>
                        <li>
                            ** বেটফেয়ার বেট যখন স্যাটেল করে তখন সে তার নিজস্ব স্কোর দেখে স্যাটেল করে। তাদের নিজস্ব স্কোর এর সাথে অন্যান্য ওয়েবসাইট এর স্কোর এর মিল নাও থাকতে পারে।
                        </li>
                        <li>
                            ** কোন বেট এ কে কত পয়েন্ট পাবে – তার স্বিদ্ধান্ত হয় বেটফেয়ার এর মাধ্যমে। যদি বেটফেয়ার এর কোন ভুল এর কারনে কোন পয়েন্ট কাটা যায় – সেই ক্ষেত্রে ইউজার রা অভিযোগ করতে পারবেন ফেসবুক গ্রূপে অথবা তার এজেন্ট কে অথবা কাস্টমার্সাভিস এ।
                        </li>
                        <li>
                            <span className='font-bold'>** সার্ভার এর সমস্যা বা টেকনিক্যাল কোন সমস্যার জন্য – বেট করতে সমস্যা বা অন্য কোন সমস্যা হয় – সেই ক্ষেত্রে ইউজার বেটফেয়ার বা Bazzi365</span> এর স্বিদ্ধান্ত ই মেনে নিতে হবে।
                        </li>
                        <li>
                            ** কোন গেইম এর রেজাল্ট এর উপরে ভিত্তি করে – বেট স্যাটেল হয়। কিন্তু বেটফেয়ার এর শর্ত অনুযায়ী তাদের ক্ষমতা আছে যে – তারা কোন গেইম এর বেট কিভাবে স্যাটেল হবে – তারা নিজেরা স্বিদ্ধান্ত নিতে পারে। তার মানে রেজাল্ট এর বাইরেও তাদের স্বিদ্ধান্ত মেনে নিতে হবে।
                        </li>
                        <li className='font-bold'>
                            বেট ভয়েড বা বাতিলঃ
                        </li>
                        <li>
                            <span className='font-bold'>* বেটফেয়ার বা Bazzi365</span> যে কোন বেট যে কোন সময়ে বাতিল করতে পারে।
                        </li>
                        <li>
                            ** বেট বাতিল এর হাজার হাজার নিয়ম আছে। এই নিয়ম এর জন্য বেট ভয়েড রুলস এর লিঙ্ক দেখতে হবে।
                        </li>
                        <li>
                            ** বেট বাতিল হলে – অই বেট এর লাভ লস কিছুই হবে না।

                        </li>
                        <li>
                            <span className='font-bold'>** বেট কেন বাতিল হল – তার কারন আমরা জানার চেষ্টা করতে পারি – Bazzi365</span> বা বেটফেয়ার থেকে। কারন হিসাবে তারা যাই বলবে তাই ইউজার কে মেনে নিতে হবে।
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Rules;