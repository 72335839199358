import React from 'react';
import Navbar from '../component/Navbar';

const Complain = () => {
    return (
        <div>
            <Navbar></Navbar>

            <div className='mt-14'>
                <div className='bg-white drop-shadow-2xl py-6 w-[90%] mx-auto rounded-lg'>
                    <h1>
                        এজেন্ট এর বিরুদ্ধে কিভাবে অভিযোগ করবেন?
                    </h1>

                    <p>
                        এজেন্ট এর সাথে আপনার যে কোন ধরনের সমস্যা থাকতে পারে। তার কিছু উদাহরন নিম্নে দেয়া হলোঃ
                    </p>

                    <div className='flex gap-2 mt-4'>
                        <div className='px-2 '><i class="uil uil-arrow-circle-right"></i></div>
                        <div>
                            এজেন্ট ঠিক মত ডিপোজিট বা উইথড্র দিচ্ছে না। 
                        </div>
                    </div>

                    <div className='flex gap-2 mt-4'>
                        <div className='px-2 '><i class="uil uil-arrow-circle-right"></i></div>
                        <div>
                            এজেন্ট এর সকাল ১০ টা থেকে রাত ১০ টা পর্যন্ত কাজ করার কথা। কিন্তু ঠিক মত করছে না।
                        </div>
                    </div>

                    <div className='flex gap-2 mt-4'>
                        <div className='px-2 '><i class="uil uil-arrow-circle-right"></i></div>
                        <div>
                            এজেন্ট ডিপোজিট এর টাকা নিয়েছে কিন্তু ঠিক মত ডিপোজিট দিচ্ছে না।
                        </div>
                    </div>

                    <div className='flex gap-2 mt-4'>
                        <div className='px-2 '><i class="uil uil-arrow-circle-right"></i></div>
                        <div>
                            আপনার আইডি লক হয়ে গেছে আনলক করছে না।
                        </div>
                    </div>

                    <p className='mt-4'>
                        এই রকম নানা অভিযোগ আপনার থাকতে পারে এজেন্ট এর বিরুদ্ধে। এই অভিযোগ গুলো র সমস্যা সমাধান এর জন্য আপনাকে প্রথমে জানতে হবে আমাদের আপ লাইন গুলো কিভাবে কাজ করে এবং কার কাছে অভিযোগ করবেন।
                    </p>

                    <p className='mt-4'>
                        মাষ্টার এজেন্ট এর বিরুদ্ধে অভিযোগ করতে হলে আপনাকে তার সুপার এজেন্টের কাছে অভিযোগ করতে হবে। যে কোন মাষ্টার এজেন্ট এর সুপার এজেন্ট কে তা বের করতে হলে আপনাকে এজেন্টের নামে র শেষে কমপ্লেইন বাটন এ ক্লিক করতে হবে। নিচের ছবি তে দেখুনঃ 
                    </p>

                    <p>Picture Option</p>

                    <p className='mt-4'>
                        কমপ্লেইন বাটন এ ক্লিক অরলে অই মাষ্টার এজেন্ট এর সুপার এজেন্ট এর নাম দেখতে পারবেন। তখন আপনি অই সুপার এজেন্ট এর কাছে অই মাষ্টার এজেন্ট এর বিরুদ্ধে অভিযোগ করতে পারবেন। ঠিক সেই রকম ভাবে সুপার এজেন্ট এর নামেও  একই ভাবে অভিযোগ করতে পারবেন।
                    </p>

                    <p className='mt-4'>
                        আপনারা ইচ্ছা করলে নিচের ভিডিও টি দেখে নিতে পারেনঃ
                    </p>

                    <p>Video Option</p>

                    <p className='mt-4'>
                        আপনি যদি কোন মাষ্টার এজেন্ট এর নামে অভিযোগ করতে চান – তাহলে তার সুপার এজেন্ট এর কাছে অভিযোগ করতে হবে। যদি সুপার এজেন্ট এর নামে অভিযোগ করতে চান তাহলে তার সাব - এডমিন এর কাছে অভিযোগ করতে হবে এমনকি যদি কোনো সাব - এডমিন এর বিরুদ্ধে অভিযোগ করার হয় তাহলে একই ভাবে উনার সিনিয়র - এডমিন এর কাছে কমপ্লেইন করবেন।
                    </p>
                    
                    <p className='mt-4'>
                        যদি আপনার অভিযোগের সমাধান সুপার এজেন্ট না দিতে পারে তাহলে আপনি কাস্টমার সার্ভিস এর কাছে অভিযোগ করবেন। যদি তিনিও দিতে না পারে তাহলে সরাসরি এডমিন কাছে অভিযোগ করবেন।
                    </p>

                    <p className='text-center text-lg font-bold'>
                        ধন্যবাদ ☺️
                    </p>


                    
                </div>
            </div>
        </div>
    );
};

export default Complain;